import styled, { css } from "styled-components";
import type { LayoutProps } from "styled-system";
import { layout } from "styled-system";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";
import { breakpoints } from "../../../../../../shared-logic/themes/toyotaTheme";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as ResultImageStyles from "./ResultImageStyles";
import type { UscObjectFitType } from "../../../../../../shared-logic/types/UscCommonTypes";

// ----------------------------------------------------------------------
// Slider buttons
// ----------------------------------------------------------------------
const SliderButton = styled.button<{ show: boolean }>`
    display: none;
    position: absolute;
    top: 50%;
    width: 34px;
    height: 36px;
    padding: 0;
    color: ${theme.colors.primaryWhite};
    background-color: ${theme.colors.primaryBlack};
    border: none;
    transform: translateY(-50%);
    transition:
        opacity 0.24s,
        visibility 0.24s;
    /* Added important because couldn't figure out how to handle the hover opacity and the button opacity. Sorry :( */
    opacity: ${({ show }) => (show ? 0.7 : 0)} !important;
    visibility: ${({ show }) => (show ? "inherit" : "hidden")};
    z-index: 10;
    svg {
        transition: transform 0.24s;
    }
    &:hover {
        opacity: ${({ show }) => (show ? 0.65 : 0)};
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const SliderLeft = styled(SliderButton)<{ invertedColour?: boolean }>`
    left: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    svg {
        color: ${({ invertedColour }) => (invertedColour ? theme.colors.primaryBlack : theme.colors.primaryWhite)};
        transition: all 0.3s;
    }
    &:hover {
        svg {
            transform: translateX(-3px);
        }
    }
`;

export const SliderRight = styled(SliderButton)<{ invertedColour?: boolean }>`
    right: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    svg {
        color: ${({ invertedColour }) => (invertedColour ? theme.colors.primaryBlack : theme.colors.primaryWhite)};
        transition: all 0.3s;
    }
    &:hover {
        svg {
            transform: translateX(3px);
        }
    }
`;

// ----------------------------------------------------------------------
// Slider viewport/tracks
// ----------------------------------------------------------------------

export const Slider = styled.div`
    position: relative;
    overflow: hidden;
    ${SliderLeft},
    ${SliderRight} {
        visibility: hidden;
    }
    &:hover {
        ${SliderLeft},
        ${SliderRight} {
            opacity: inherit;
            visibility: inherit;
        }
    }
`;
export const SliderViewport = styled.div<{
    objectFit: UscObjectFitType;
    hasAspectRatio43?: boolean;
    isRetailer?: boolean;
}>`
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-bottom: ${({ hasAspectRatio43, isRetailer }) => (isRetailer ? "0%" : hasAspectRatio43 ? "75%" : "56.25%")};
    background: ${theme.colors.grey1};
    overflow: hidden;

    ${ResultImageStyles.Image} {
        width: 100%;
        object-fit: ${({ objectFit }) => objectFit};
    }
`;

type TrackType = { width?: number; position: string; left: number; enableTransition: boolean };
export const Track = styled.div.attrs<TrackType>((props) => ({ style: { transform: `translateX(${props.left}px)` } }))<
    TrackType & LayoutProps
>`
    display: flex;
    position: ${(props) => props.position};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    flex-direction: row;
    height: 100%;
    z-index: 3;
    ${layout};
    ${(props) =>
        props.enableTransition &&
        css`
            transition:
                opacity 0.3s,
                transform 0.5s;
        `};
`;

export const SliderIndex = styled.div<{ has360?: boolean; hasVideo?: boolean }>`
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 4;
    ${({ hasVideo, has360 }) =>
        hasVideo && !has360
            ? css`
                  right: 30px;
              `
            : null};
    ${({ has360, hasVideo }) =>
        has360 && !hasVideo
            ? css`
                  right: 50px;
              `
            : null};
    ${({ has360, hasVideo }) =>
        has360 && hasVideo
            ? css`
                  right: 70px;
              `
            : null};
`;

export const IconWrapper = styled.div`
    display: inline-flex;
    padding: ${theme.space[1]}px ${theme.space[2]}px;
    color: ${theme.colors.primaryWhite};
    background-color: ${theme.colors.primaryBlack};
    border-radius: ${theme.space[1]}px ${theme.space[1]}px 0 0;
`;

export const SliderBottom = styled.div<{ invertedColour: boolean }>`
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    align-items: flex-end;
    justify-content: center;
    column-gap: ${theme.space[1]}px;
    width: 100%;
    color: ${({ invertedColour }) => (invertedColour ? theme.colors.primaryBlack : theme.colors.primaryWhite)};
    z-index: 4;

    /* When the 360 icon is available in the Slider */
    svg {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const Location = styled.div<{ invertedColour: boolean }>`
    display: flex;
    align-items: center;
    margin-${getDirection("right")}: auto;
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${({ invertedColour }) => (invertedColour ? theme.colors.primaryBlack : theme.colors.primaryWhite)};

    svg {
        margin-${getDirection("left")}: 0;
        margin-${getDirection("right")}: 10px;
    }
`;

export const LocationPlace = styled.span`
    font-family: ${theme.fonts.regular};
`;

export const BaseImage = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: 100%;
    z-index: 2;
`;
