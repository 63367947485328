import styled, { css } from "styled-components";
import type { OffsetAlignType } from "../../../../hooks/usePopoverTooltip";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import * as Button from "./Button";
import type { ColorType } from "../theme/toyotaTheme";
import { theme } from "../theme/toyotaTheme";
import { bodyStyle } from "../globals/Type";
import IconWrapper from "../globals/IconWrapper";

export const ARROW_HORIZONTAL_OFFSET = 30;
export const ARROW_SIZE = 9;

/**
 * Since iOS version 13 (iPadOS), Safari started zooming out when elements are rendered outside of the viewport
 * This breaks the logic of our dynamic popover which is only moved to a valid position after the first render, causing Safari to zoom out.
 *
 * To fix this we wrap the tooltip in a div with width=0 on initial render,
 * since the actual tooltip also has a fixed width this does not interfere with the position calculations.
 *
 * See OR-3596
 */
export const BoxWrapper = styled.div<{ hide?: boolean }>`
    width: ${({ hide }) => (hide ? "0px" : "initial")};
    color: ${theme.colors.primaryWhite};
    overflow: ${({ hide }) => (hide ? "hidden" : "initial")};
`;

type TooltipPropsType = {
    absolute?: boolean;
    point?: OffsetAlignType;
    visible?: boolean;
    offset?: { x: number; y: number };
    small?: boolean;
    disabled?: boolean;
    zIndex?: number;
};

const SmallTooltipStyles = css`
    min-width: 200px;
    padding: 5px 9px;
`;

const NormalTooltipStyles = css`
    width: 300px;
    padding: ${theme.space[3]}px;
    @media ${getBreakpoint("up", "sm")} {
        width: 325px;
    }
`;

export const Wrapper = styled.div<TooltipPropsType>`
    display: ${({ disabled }) => (disabled ? "none" : "flex")};
    position: ${({ absolute }) => (absolute ? "fixed" : "relative")};
    flex-direction: column;
    min-width: 250px;
    padding: 5px 9px;
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: ${getDirection("left")};
    text-shadow: none;
    text-transform: none;
    color: ${theme.colors.primaryWhite};
    background: ${theme.colors.primaryBlack};
    box-shadow: 0 0 10px rgba(40, 40, 48, 0.15);
    transition: opacity 0.15s;
    pointer-events: ${(props) => (props.visible === undefined || props.visible === true ? "auto" : "none")};
    opacity: ${(props) => (props.visible === undefined || props.visible === true ? 1 : 0)};
    z-index: ${(props) => (props.zIndex ? props.zIndex : theme.zIndex.tooltip)};

    ${({ offset }) =>
        offset &&
        css`
            /* Use -9999 to initially position the Tooltip off-screen,
           preventing it from being visible in the upper left corner on Tizen 4 */
            top: ${offset.y || -9999}px;
            left: ${offset.x || -9999}px;
        `};

    ${(props) => (props.small ? SmallTooltipStyles : NormalTooltipStyles)};

    ${({ point }) =>
        point &&
        css`
            /* Add a margin for the pointer mouseLeave events. */
            margin-bottom: ${ARROW_SIZE + 20}px;
            &::after {
                display: block;
                position: absolute;
                ${point.horizontal}: ${ARROW_HORIZONTAL_OFFSET}px;
                ${point.vertical}: ${-ARROW_SIZE + 1}px;
                height: 0;
                width: 0;
                content: " ";
                border-${getDirection("left")}: ${ARROW_SIZE}px solid transparent;
                border-${getDirection("right")}: ${ARROW_SIZE}px solid transparent;
                border-${point.vertical === "top" ? "bottom" : "top"}: ${ARROW_SIZE}px solid ${
                    theme.colors.primaryBlack
                };
                }
        `};

    p {
        text-indent: 0;
    }

    a {
        color: ${theme.colors.primaryWhite};
    }
`;

/* "pointer-events: none" is necessary on the content of a tooltip because pointer events would break the close modal flow. */
export const Content = styled.div`
    pointer-events: none;
`;

export const Close = styled.button<{ iconOnly?: true }>`
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: ${theme.space[2]}px;
    padding: ${theme.space[1]}px ${theme.space[2]}px ${theme.space[1]}px ${theme.space[1]}px;
    line-height: 2rem;
    color: ${theme.colors.primaryWhite};
    border: 1px solid ${theme.colors.primaryWhite};
    border-radius: 17.5px;
    cursor: pointer;

    svg + span {
        display: inline-block;
        position: relative;
        top: -1px; /* Fix line-height issue -> text isn't center aligned */
        margin-${getDirection("left")}: ${theme.space[1] / 2}px;
    }

    ${({ iconOnly }) =>
        iconOnly &&
        css`
            justify-content: center;
            width: 3rem;
            height: 3rem;
            padding: ${theme.space[1]}px;
            border-radius: 50%;

            svg + span {
                margin-${getDirection("left")}: 0;
            }
        `};

    /* Override foundation style */
    .icon,
    .icon::before {
        line-height: 1 !important;
    }
`;

export const HitBoxButtonSpacer = styled.div`
    position: absolute;
    top: -10px;
    width: 50px;
    height: 50px;
    ${getDirection("left")}: -15px;
`;

export const PopoverButton = styled(Button.Link)<{ iconColor?: ColorType }>`
    display: flex;
    position: relative;
    padding: 0;
    line-height: 1;
    .icon::before {
        position: relative;
        top: 2px;
        font-size: 1.6rem;
        color: ${({ iconColor }) => (iconColor ? theme.colors[iconColor] : theme.colors.primaryBlack)};
    }
`;

/**
 * Workaround to proper align a tooltip next to a label
 */
export const Label = styled.div``;

export const TooltipWrapper = styled.div<{ width?: number }>`
    display: inline-flex;
    width: 20px;
    height: 20px;

    ${Label} {
        flex: 0 0 ${({ width }) => width || 200}px;
    }

    ${PopoverButton} {
        .icon::before {
            top: -1px;
        }
    }
`;

/**
 * Tooltip button
 */
export const TooltipButton = styled.button<{ isSmall?: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${theme.space[1]}px;
    color: ${theme.colors.grey3};
    ${bodyStyle};

    &:hover {
        color: ${theme.colors.grey4};
    }

    ${IconWrapper} {
        width: 20px;
        height: 20px;
    }

    /* Fix position icons in tooltip */
    .dxp-icon {
        width: 20px;
        height: 20px;
        color: ${theme.colors.grey7};
        transform: translate(-1px, 1px);
        &::before {
            font-size: 2.4rem;
        }
    }
`;
