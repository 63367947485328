/**
 * Common types used in build and buy flow.
 */
import type { VoidFunctionComponent } from "react";
import type {
    CompareV2ModalIdsType,
    CompareV2ModalsType,
} from "../../shared-logic/features/compare/utils/modalConstants";
import type { CertificationType, WinterWheelCategoryType, FlexMatrixPriceType } from "../constants";
import type { CarFilterModalIdsType, CarFilterModalsType } from "../../used-stock-cars/car-filter/utils/modalConstants";
import type { BnBModalIdsType, BnbModalsType } from "../../new-cars/original/buildandbuy/utils/modalConstants";
import type { CommonModalIdsType, CommonModalsType, DebugModalIdsType, DebugModalsType } from "../utils/modalConstants";
import type { T1LabelType } from "../utils/t1labels";
import type { AemFmPriceType } from "../utils/aemFlexibilityMatrixUtils";
import type { CompareUscModalIdsType, CompareUscModalsType } from "../../used-stock-cars/compare/utils/modalConstants";
import type { EquipmentAvailabilityType } from "../server/texusEquipmentTypes";
import type { TradeInAemModalIdsType, TradeInAemModalsType } from "../../tradein/utils/modalConstants";
import type {
    LeasingFilterModalIdsType,
    LeasingFilterModalTypes,
} from "../../new-cars/leasing/leasing-filter/utils/modalConstants";
import type { BaseType, UnitType } from "../../used-stock-cars/shared-logic/server/UsedCarsAPIResultTypes";
import type { GradeEquipmentType } from "./GradeType";
import type { SortedSpecType } from "./SpecTypes";
import type {
    LeasingDetailsModalIdsType,
    LeasingDetailsModalTypes,
} from "../../new-cars/leasing/leasing-details/utils/modalConstants";
import type {
    UscFormModalIdsType,
    UscFormModalsType,
} from "../../used-stock-cars/used-stock-cars-form/utils/modalConstants";
import type { AphModalIdsType, AphModalsType } from "../../new-cars/apheleia/carconfig/utils/modalConstants";
import type {
    GradeSelectorModalIdsType,
    GradeSelectorModalsType,
} from "../../new-cars/apheleia/grade-selector/utils/modalConstants";
import type { ItemAvailabilityType } from "../../new-cars/shared-logic/utils/classes/CarDataConsolidator";
import type { CmcModalIdsType, CmcModalsType } from "../../new-cars/apheleia/cross-model-compare/utils/modalConstants";
import type { UscModalIdsType, UscModalsType } from "../../used-stock-cars/pdp/utils/modalConstants";

export type ColouringModeType = 0 | 1 | 2 | 4;

export type PersonalizationType = "wheel" | "colour" | "upholstery";

// Only one type currently, but build to scale up if they want to have older generations as well.
export enum ModelGenerationType {
    Next = "next",
}

export type FinanceOptionType = "cash" | "monthly";

export type AvailabilityType = {
    colours: string[];
    upholsteries: string[];
};

export enum RequestStatusEnum {
    Idle = "idle",
    Fetching = "fetching",
    Complete = "complete",
    Error = "error",
    Aborted = "aborted",
}

export type AbortableRequestStatus = RequestStatusEnum;

export type RequestStatus = Exclude<RequestStatusEnum, RequestStatusEnum.Aborted>;

export type FetchAndParseJsonType = (
    uri: string,
    type: string,
    body?: { [index: string]: any },
    method?: "GET" | "POST",
) => any;

// Type of the data we receive from the FQS service
export type UpdateQuoteConfigType = {
    finance?: string | null;
    productName?: string;
    insurance?: string | null;
};

export type FinanceInfoDisclaimerType = {
    value: string;
    title?: string;
    table?: {
        name: string;
        value: string;
        tooltip?: string;
    }[];
};

// Used for monthly rate formatting throughout OR.
export type CommonFinanceInfoType = {
    annualInterestRate: string;
    disclaimer?: FinanceInfoDisclaimerType | null;
    effectiveInterestRate: string;
    productName?: string;
    productType?: string;
    term: string;
    residualValue?: string;
    downPayment?: string;
    mileage?: string;
    financingType?: string;
    duration?: string;
    interestRate?: string;
    lastInstalment?: string;
    installments?: string;
    totalAmountFinanced?: string;
    amountFinanced?: string;
    totalCreditCostWithFee?: string;
};

export enum PromotionTypeEnum {
    SpotLight = "SPOTLIGHT",
    PercentageDiscount = "PERCENTAGEDISCOUNT",
    FixedPriceDiscount = "FIXEDPRICEDISCOUNT",
    None = "NONE",
}

export type PricePromotionType = {
    index: number;
    id: string;
    name: string;
    amount: number;
    type: PromotionTypeEnum;
    description: string;
    ecoBonus?: boolean;
};

export type PromotionType = PricePromotionType & {
    shortId: string;
    description: string;
    target: string;
    from?: string;
    until?: string;
    disclaimer?: string;
    customerOptInRequired: boolean;
    customerOptInRequiredLabel: string;
    onlineMonthlyRate: boolean;
    onlineCash: boolean;
    issuerCode?: string;
    ecoBonus: boolean;
};

export type PriceType = {
    cash: number;
    monthly: number;
    // monthlyWithoutPromotion is used for the grade, model, car explorer and grade explorer step
    monthlyWithoutPromotion?: number;
    discount: number;
    promotions: PricePromotionType[];
    // Highlight is a type of promotion which does not alter the price but should show the text as highlighted.
    highlight: boolean;
    // Required for WLTP total price calculations, *only* use this for WLTP calculations as this is an edge case property.
    exclVat: number;
    // Required for Israel price calculation
    licenseFee: number;
    // Online only discounts and promotions
    onlineCashDiscount: number;
    onlineMonthlyDiscount: number;
    onlineCashPromotions: PricePromotionType[];
    onlineMonthlyPromotions: PricePromotionType[];
};

export enum CarModelLabelCodeType {
    CASH_PRICE_DISCLAIMER,
}

export type CarModelLabelType = {
    code: CarModelLabelCodeType;
    value: string;
};

export type LicenseFeesType = { fee: number; min: number; max: number }[];

export type PriceConfigType = {
    // It might be 'cleaner' to have this as a generic but its not worth it for now.
    priceType: FlexMatrixPriceType | AemFmPriceType;
    priceFormat: string;
    priceEnabled: boolean;
    strikethroughDisabled?: boolean;
};

// This differs slightly from PricePromotionType and is used together with C1 car filter api
export type NewCarResultPromotionType = {
    id: string;
    name: string;
    disclaimer: string;
    ecoBonus?: boolean;
    from: string;
    until: string;
    issuerCode?: string;
};

export enum EcoTagType {
    Hybrid = "hybrid",
    Available = "available",
    FuelCell = "fuelCell",
    Electric = "electric",
    Plugin = "plugin",
    Petrol = "petrol",
    Diesel = "diesel",
}

export const ecoTagLabelMap: Record<EcoTagType, T1LabelType> = {
    [EcoTagType.Hybrid]: "hybrid",
    [EcoTagType.Available]: "hybridAvailable",
    [EcoTagType.FuelCell]: "fuelCell",
    [EcoTagType.Electric]: "electric",
    [EcoTagType.Plugin]: "plugin",
    [EcoTagType.Petrol]: "petrol",
    [EcoTagType.Diesel]: "diesel",
};

export type ModalsType =
    | CommonModalsType
    | DebugModalsType
    | CompareV2ModalsType
    | CompareUscModalsType
    | BnbModalsType
    | UscModalsType
    | UscFormModalsType
    | CarFilterModalsType
    | TradeInAemModalsType
    | LeasingFilterModalTypes
    | LeasingDetailsModalTypes
    | AphModalsType
    | GradeSelectorModalsType
    | CmcModalsType;

export type ModalIdsType =
    | CommonModalIdsType
    | DebugModalIdsType
    | CompareV2ModalIdsType
    | CompareUscModalIdsType
    | BnBModalIdsType
    | UscModalIdsType
    | UscFormModalIdsType
    | CarFilterModalIdsType
    | TradeInAemModalIdsType
    | LeasingFilterModalIdsType
    | LeasingDetailsModalIdsType
    | AphModalIdsType
    | GradeSelectorModalIdsType
    | CmcModalIdsType;

// The required hard link between a modal's type field and it's props seem to be impossible, so you can pass any as type arg for now
export type ModalMapType<T extends ModalIdsType = ModalIdsType> = Partial<Record<T, VoidFunctionComponent<any>>>;

// Base type used for a Modal component.
export type ModalViewType<T extends ModalsType> = {
    show: boolean;
    close: () => void;
    modalSettings: T;
};

// TODO Types below are mainly used in the Build, consider splitting these into another file.
export type ColourType = {
    id: string;
    shortId: string;
    code: string;
    internalCode: string;
    name: string;
    type: string;
    price: PriceType;
    rgb: string;
    standard: boolean;
    image: string;
    upholsteries: string[]; // Contains the upholsteries available for this colour
    upholsteriesCarConfigurator: string[];
    upholsteryOnlineAvailability: string[]; // contains the upholsteries for this colour that are available for online sales
    description: string | null;
};

export type UpholsteryType = {
    id: string;
    objectType: "upholstery";
    shortId: string;
    internalCode: string;
    name: string;
    price: PriceType;
    default: boolean;
    standard: boolean;
    image: string;
    description: string | null;
    index: number;
    sortIndex: number;
    availableForColours: string[];
};

export type ExtraBaseType = {
    id: string;
    shortId: string;
    code: string;
    name: string;
    index: number;
    type: "option" | "accessory";
    price: PriceType;
    standard: boolean;
    image: string;
    included: string[];
    excluded: string[];
    includePacks: string[];
    excludePacks: string[];
    description: string | null;
    availability: AvailabilityType;
    sortIndex: number;
};

export type HomeChargerType = ExtraBaseType & {
    isWheel: false;
    isHidden: boolean;
    footNote: string;
    description: string;
    image: string;
};

export type CategoryType = {
    id: string;
    name: string;
    sortIndex: number;
    parent: CategoryType | null;
    root: CategoryType | null;
    code: string;
    level: number;
};

//TODO:  id,name, isSelected should not be nullable...
export type ExtrasCategoryToFilterType = {
    id: string | null;
    name: string | null;
    code?: string;
    isSelected: boolean | null;
    count?: number;
};

export type ExtraSetItemType = {
    name: string;
    id: string;
    shortId: string;
    showsOnCar: boolean;
    availability: AvailabilityType;
    type: "option" | "accessory";
    colouringMode: number;
    colour: AccentColourType | null;
    included: string[];
    excluded: string[];
};

export type ExtraType = ExtraBaseType & {
    index: number;
    objectType: "extra";
    isWheel: boolean;
    showsOnCar: boolean;
    internalCode: string;
    path: string;
    // parent is the actual equipment object as returned by Texus :s
    parent: any;
    colouringMode: number;
    isUpholstery: boolean;
    // Property used to also provide hidden equipment (= equipment which should not be shown in car-config) to specific services, ex.: WLTP specification service
    isHidden: boolean;
    isPackExtra: boolean;
    // Used in pack equipment items where if a pack contains exterior colours, these are defined on this property of a pack equipment item
    exteriorColours: ColourType[];
    // Used in pack equipment items where if a pack contains upholsteries, these are defined on this property of a pack equipment item
    upholsteries: UpholsteryType[];
    options: ExtraType[];
    selectedOptionId: string; // This is only relevant for selected extras in the basket.
    colour: AccentColourType | null;
    category: CategoryType;
    isMostPopular: boolean;
    mostPopularIndex: number;
    setItems: ExtraSetItemType[];
    userSelected?: boolean;
};

// This an object containing the root category name and id in addition to the extras of the category.
export type ExtraCategoryType = {
    extras: ExtraType[];
    id: string;
    name: string;
    code: string;
    sortIndex: number;
};

export type WheelType = ExtraBaseType & {
    objectType: "wheel"; // ObjectType is used to be able to see the difference between a wheel or an extra in flowtyping.
    isWheel: true;
    properties?: { Code: string; Name: string; Value: string; TypeCode: number };
    setItems: ExtraSetItemType[];
};

export type WinterWheelType = ExtraBaseType & {
    wheelCategory: WinterWheelCategoryType | undefined;
    objectType: "winterWheel";
    description: string;
    isWheel: true;
    id: string;
    image: string;
    rimName: string;
    tyreName: string;
    name: string;
    setPartNumber: string;
    price: PriceType;
    setProperties: string;
    euLabelProperties?: {
        fuelEfficiency: string;
        wetGrip: string;
        noise: string;
    };
    tyreLabelEPRELCode: string;
    tyre: TyreType | undefined;
    installationCost: PriceType | null;
    installTheWheels: boolean;
    type: string;
    setItems: ExtraSetItemType[];
    visible: boolean;
    availability: AvailabilityType;
    valid: boolean;
    causes: string[];
};
export type FormattedDebugTyre = {
    internalCode: string;
    eprelValue: string;
    standard: boolean;
    [key: string]: string | boolean;
};

export type FormattedDebugWinterWheel = {
    name: string;
    setPartNumber: string;
    wheelCategory: string | undefined;
    visible: boolean;
    causes: string[] | undefined;
    rimName: string;
    tyre: FormattedDebugTyre | string;
    image: string;
    price: {
        cash: number;
        discount: number;
        exclVat: number;
    };
    installationCost: {
        cash: number;
        discount: number;
        exclVat: number;
    } | null;
    tyreLabelEPRELCode: string;
};

export type PersonalisationItemType = ColourType | WheelType | UpholsteryType | ExtraType;

//// Packs!
export type AccentColourType = {
    id: string;
    code: string;
    name: string;
    rgb: string;
    default: boolean;
    image: string;
    // A primary accent colour can contain secondary accent colours
    accentColours: AccentColourType[];
};

export type PackVisualItemType = {
    standard: ExtraType[];
    optional: ExtraType[];
    wheels: WheelType[];
    colours: ColourType[];
    upholsteries: UpholsteryType[];
    equipmentUpholsteries: ExtraType[];
};

export type PackType = {
    index: number;
    id: string;
    shortId: string;
    objectType: "pack";
    name: string;
    standard: boolean;
    extended: {
        accentColours: {
            // Hash table of body colours which contain in their turn a list of primary accent colour
            [id: string]: AccentColourType[];
        };
    } | null;
    availability: AvailabilityType;
    visualItems: PackVisualItemType;
    description?: string;
    equipment: (WheelType | ExtraType)[];
    serviceProducts: ServiceProductType[];
    image: string | null;
    footNote: string | null;
    equipmentDescription?: string;
    included: string[];
    excluded: string[];
    includePacks: string[];
    excludePacks: string[];
    subtitle: string;
    price: PriceType;
    userSelected?: boolean;
    standardOnMonthlyRateToggle: boolean;
    sortIndex: number;
};

export type PackAvailabilityType = Omit<ItemAvailabilityType, "available"> & { enabled: boolean };

export type ServiceProductType = {
    id: string;
    shortId: string;
    name: string;
    description?: string;
    standard: boolean;
    image: string | null;
    footNote: string;
    exclusiveOnline: boolean;
    financeable: boolean;
    tooltip: string;
    price: PriceType;
    includeService: {
        shortId: string;
        name: string;
    }[];
    excludeService: {
        shortId: string;
        name: string;
    }[];
};

export type TyreType = {
    id: string;
    internalCode: string;
    shortId: string;
    standard: boolean;
    eprelValue: string;
    tyreLabels: {
        id: string;
        label: string;
        value: string;
    }[];
};

// Used for model step.

export type BaseModelGradeType = {
    id: string;
    name: string;
    bodyType: { id: string; index: number; name?: string };
    price: PriceType;
    finance?: CommonFinanceInfoType;
    efficiencyClass: string;
};

export type ModelGradeType = BaseModelGradeType & {
    index: number;
    subtitle: string;
    ecoTag: EcoTagType | false;
    defaultCarId: string;
    availability: EquipmentAvailabilityType[];
    motorizations: ModelGradeMotorizationType[];
    standardEquipment: GradeEquipmentType[];
    listEquipment: SortedSpecType[];
    submodelId: string;
    availableForOnlineSales: boolean;
    availableForOnlineLeasing: boolean;
    availableForBusinessLeasing: boolean;
};

export type ModelGradeSpec = {
    id: string;
    name: string;
    internalCode: string;
    value: string;
    unit: string;
    certification: CertificationType;
};

export type ModelGradeFullSpec = {
    id: string;
    name: string;
    internalCode: string;
    maxValue: string;
    minValue: string;
    summary: string;
    certification: CertificationType;
};

export type ModelGradeMotorizationType = {
    carID: string;
    engine: EngineType;
    transmission: {
        id: string;
        name: string;
    };
    wheeldrive: {
        name: string;
        id: string;
    };
    price: PriceType;
    finance?: CommonFinanceInfoType;
    specs: ModelGradeSpec[];
    fullSpecs: ModelGradeFullSpec[];
    soldOut: boolean;
    availableForBusinessLeasing: boolean;
};

export type FilterEquipmentType = {
    id: string;
    shortId: number;
    name: string;
    footNote: string;
    standard: boolean;
    type: string;
};

export type ModelType = {
    id: string;
    name: string;
    description: string;
    internalCode: string;
    filterItems: FilterEquipmentType[];
    grades: ModelGradeType[];
    defaultCarId: string;
    configVersion: string;
    labels: CarModelLabelType[];
};

type NotificationAction = {
    targetButton?: { text: string; target: string };
    closeButton?: boolean;
};

export type NotificationType = {
    title: string;
    description: string;
    action?: NotificationAction; // To pass an optional scrollTo action that can be used inside the notification onClick.
    displaySettings?: {
        notificationIndex: number;
        isReady: boolean; // Needed so onAnimationEnd can work dynamically.
        isAnimating: boolean; // Needed so onAnimationEnd can work dynamically.
        linkedNotificationIndex?: number; // Notifications can be linked, e.g. if a colour & upholstery notification was added at the same time, they also need to fade away simultaneously.
    }; // Used to dynamically show notifications with animations.
};

export type ModelFilterDimensionType = { id: string; name: string; value: string; unit: string };
export type ModelFilterSelectedOptionsType = {
    standard: boolean;
    type: string;
    id: string;
    shortId: string;
    showsOnCar: boolean;
    price: PriceType;
};
export type ModelFilterCarType = {
    id: string;
    shortId: string;
    bodyTypeId: string;
    name: string;
    filterValues: { id: string; code: string }[];
    selectedOptions: ModelFilterSelectedOptionsType[];
    capacity: ModelFilterDimensionType[];
    dimensions: ModelFilterDimensionType[];
    grade: { id: string; name: string };
    bodyType: { id: string; name: string };
    price: PriceType;
    promoted: boolean;
    finance: CommonFinanceInfoType;
    index: number;
};
export type ModelFilterGradeType = BaseModelGradeType & {
    carId: string;
    capacity: ModelFilterDimensionType[];
    dimensions: ModelFilterDimensionType[];
    selectedOptions: ModelFilterSelectedOptionsType[];
    filterIndex: number;
};

export type FilterValueType = {
    id: string;
    label: string;
};

export enum ProaceFilterType {
    SeatsCount = "CAP_SLIDER_3", // custom view with images
    WheelBase = "TYPE_SLIDER_2", // custom view with dimensions modal
    MainBodytype = "TYPE_SLIDER_1",
    WheelbaseAndRoof = "TYPE_SLIDER_2",
    MaxPayload = "CAP_SLIDER_4",
    LoadVolume = "CAP_SLIDER_5",
    SideDoors = "DOORS_SLIDER_1",
    RearDoors = "DOORS_SLIDER_2",
    CappedLoadvolume = "CAP_SLIDER_2", // difference in compare mode with loadVolume
}

export type FilterType = {
    default: string;
    label: string;
    code: ProaceFilterType;
    values: FilterValueType[];
};

export type FilterInfoType = {
    cars: ModelFilterCarType[];
    filters: FilterType[];
};

export type CarFilterValueType = {
    id: string;
    code: string;
};

export type AlternativeType = {
    id: string;
    shortId: string;
    price: PriceType;
    financeInfo?: CommonFinanceInfoType;
    eta: { date: string | false };
    model: { id: string; name: string; internalCode: string };
    grade: { id: string; name: string };
    body: { id: string; name: string };
    motorization: {
        engine: { name: string };
        transmission: { name: string };
    };
    colour: ColourType;
    upholstery: UpholsteryType;
    carImage: string;
};

export type EngineType = {
    description: string;
    fuelCell: boolean;
    hybrid: boolean;
    plugin: boolean;
    electric: boolean;
    id: string;
    image: string;
    name: string;
    type: string;
    typeCode: string;
    certification: CertificationType;
    efficiencyClass: string;
    engineNumber?: string;
};

export type CCSaveCommonParametersType = {
    FinanceOption?: FinanceOptionType;
    FinanceQuoteID?: string;
    InsuranceQuoteID?: string;
    TradeInQuoteID?: string;
    UserDepartmentCode?: string;
    HomeChargingType?: string;
    Origin?: "build" | "business-leasing-details" | "leasing-details" | "apheleia-carconfig";
};

// Return type when ccsave returns an error.
export type CCSaveErrorType = {
    type: string;
    source: unknown;
    message: string;
    stackTrace: null | string;
};

// ErrorLogs should be used to properly propagate (cached) errors to the frontend.
export type ErrorLogType = {
    id?: string;
    title: string;
    message: string | string[];
    innerMessage?: string;
    // If any errorLog in a FetchFnType has "errorCache: true" the result will be treated like a failed fetch by RedisCache/MemoryCache.
    // This means the caching strategy will be adjusted to treat the fetch like a failure (shorter cache time and other fallback mechanisms)
    // If none of the errorLogs have "errorCache: true" the fn will be treated like a successful fetch
    // If the fetch result is not cached errorCache has no impact.
    errorCache?: boolean;
};

export type TridionBaseType = { tcmId: string; componentName: string; version: number; metaData?: unknown };

export type AEMMultimediaType = {
    binary: { url: string; filesize: string };
    metaData: { height?: string; width?: string; layout?: { default: string }; expireCloudCache?: string };
};

export type BooleanStringType = "true" | "false";

// These are the supported external-web-component in Tridion. Not every country has all of these available.
export type ExternalWebComponentType =
    | "or/filter"
    | "or/tradein"
    | "or/buildbuy"
    | "gpel/standard"
    | "gpel/optional"
    | "gpcompare"
    | "gpproace"
    | "carfilter"
    | "usedstockcars";

export type RequestExceptionReturnType = {
    errorMessage: string;
    code: string;
    uniqueId: string;
    details?: unknown | null;
    stack?: string | null;
};

export enum AssetSizeType {
    BIG = "BIG",
    MEDIUM = "MEDIUM",
}

export type FilterAssetType = { type: AssetSizeType; file: string };

export enum DisclaimerLocation {
    InfoIcon = "infoIcon", // Default, show the disclaimer as an info icon which triggers a popover or modal.
    InfoText = "infoLink", // Similar to infoIcon but instead of an icon we show a clickable link which triggers a modal
    Footnote = "footnote", // Show the disclaimer in the footnote with a numbered reference.
    Inline = "inline", // Most obvious example is TDK, show the disclaimer straight under its reference.
}
export type DisclaimerType<T extends string> = {
    type: T; // Identifies the disclaimer
    reference?: string; // An additional id to be used with the disclaimer type to identify a disclaimer (for example spec id)
    number?: number; // Footnote number
    value: string; // Disclaimer content
    containsHtml?: boolean;
    location: DisclaimerLocation; // See enum for more info
    infoText?: string; // Used with the disclaimerLabel.infoText label. This contains the text used to trigger the disclaimer.
};

export type QueryObjectType = {
    label: string;
    queryValues: (string | null)[];
    isDropdown?: boolean;
    currentValue: string | boolean;
    inputTouched?: false;
};

// Simple helper types for mouse/touch position calculations.
export type PosType = { x: number; y: number };

export type GetGradeCardImageType = (
    width: number,
    height: number,
    gradeId: string,
    bodyTypeId: string,
    angle: number,
) => string;

export type UsedCarsTranslationResultType = {
    description: string;
    language: string;
};

export type DfEngineType = {
    cylinders?: number;
    description?: string;
    displacement: UnitType;
    engineKey?: BaseType;
    fiscalHorsepower?: UnitType;
    fuelType: BaseType;
    marketingFuelType: BaseType;
    hybrid: boolean;
    powerOutputHorsepower: UnitType;
    powerOutputKilowatts?: UnitType;
    engineNumber?: string;
};

export type EtaStateType = {
    loading: boolean;
    loaded: boolean;
    error: boolean;
    date: string | false;
    alternatives: AlternativeType[];
};

// Very inconsistent syntax, these were decided by another team ¯\_(ツ)_/¯
export type AEMFormTypes =
    | "brochure"
    | "complaint-inquiry"
    | "complaint"
    | "dealer"
    | "newsletter"
    | "quoterequest"
    | "recall-checker"
    | "Test drive";
