import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import type {
    EngineType,
    ExtraType,
    FinanceOptionType,
    HomeChargerType,
    PackType,
    ServiceProductType,
    WheelType,
    WinterWheelType,
} from "../../../common-deprecated/types/CommonTypes";
import { EcoTagType } from "../../../common-deprecated/types/CommonTypes";
import type { CarMotorizationType } from "../types/CarType";
import type { BasketType } from "../types/BasketType";
import type { ItemsType } from "../../../common-deprecated/redux/typeHelpers";
import CarDataConsolidator from "./classes/CarDataConsolidator";
import { find, getCARDBPath } from "../../../common-deprecated/utils";
import type {
    ExteriorColourTexusType,
    ColourTexusType,
    ColourFromOtherGradesTexusType,
} from "../../../common-deprecated/types/TexusTypes";

/**
 * Attach modelToken/disableTokens/priceCalculationService query variables to the given url.
 */
export const addTokenDebugVariablesToUrl = (commonSettings: CommonSettingsType, url: string): string => {
    const { disableTokens, modelToken, priceCalculationService } = commonSettings.query;
    const items = [
        typeof disableTokens !== "undefined" && `disableTokens=${disableTokens.toString()}`,
        modelToken && `modelToken=${modelToken}`,
        priceCalculationService && `priceCalculationService=${priceCalculationService.toString()}`,
    ].filter(Boolean);

    return `${url}?${items.join("&")}`;
};

/**
 * Takes the engine type of the FetchMotorization call (engine step) and the engine type of the FetchFullConfig call (customize+)
 * Returns which ecoTag should be displayed
 */
export const getEcoTagType = (engine: EngineType | CarMotorizationType["engine"]): EcoTagType | undefined => {
    if (engine.fuelCell) return EcoTagType.FuelCell;
    if (engine.plugin) return EcoTagType.Plugin;
    if (engine.hybrid) return EcoTagType.Hybrid; // both fuelCell and plugin can be hybrids they should be checked first
    if (engine.electric) return EcoTagType.Electric;
    return undefined;
};

/**
 * Helper function to get an array of option/accessory ids. Will add childExtras and its parents depending on settings.
 * Casts shortIds to strings to have an easier flow setup (avoid an array with multiple return types).
 */
export const getExtraIds = (
    extraType: "option" | "accessory",
    arr: (ExtraType | WheelType | WinterWheelType | HomeChargerType)[],
    useShortIds: boolean,
    excludeStandard: boolean,
): string[] =>
    arr.reduce((opts: string[], extra) => {
        const pushUnique = (value: string): number => (!opts.includes(value) ? opts.push(value) : 0);
        if (
            extra.type === extraType &&
            (!excludeStandard || (extra as ExtraType).isPackExtra || (excludeStandard && !extra.standard))
        ) {
            if (extra.isWheel) {
                pushUnique(extra[useShortIds ? "shortId" : "id"].toString());
            } else {
                const childOption = CarDataConsolidator.getSelectedExtraOption(extra as ExtraType);
                if (childOption) {
                    pushUnique(childOption[useShortIds ? "shortId" : "id"].toString());
                } else {
                    pushUnique(extra[useShortIds ? "shortId" : "id"].toString());
                }
            }
        }
        return opts;
    }, []);

export const getExternalConfigOptionsAccessoryIds = (
    basket: BasketType,
    items: ItemsType,
): { options: string[]; accessories: string[] } => {
    const { wheel } = basket;
    const combinedExtras: (ExtraType | WheelType | WinterWheelType | HomeChargerType)[] = [
        ...CarDataConsolidator.getAllRelevantBasketExtras(basket, items),
        ...basket.homeChargers,
    ];

    combinedExtras.push(wheel);

    // Add the winterwheel
    if (basket.winterWheel) {
        combinedExtras.push(basket.winterWheel);
    }

    const data: { options: string[]; accessories: string[] } = { options: [], accessories: [] };

    if (combinedExtras.length > 0) {
        data.options = getExtraIds("option", combinedExtras, false, false);
        data.accessories = getExtraIds("accessory", combinedExtras, false, false);
    }

    return data;
};

/**
 * @deprecated Use the same function but without deprecated instead
 */
export const getExternalConfigOptionsAccessoryIdsDeprecated = (
    basket: BasketType,
    items: ItemsType,
    useShortIds: boolean = false,
    excludeStandard: boolean = false,
    // TODO Clean this up with service product refactor
    financeOption: FinanceOptionType | null = null,
): { options: string[]; accessories: string[] } => {
    const { wheel } = basket;
    const combinedExtras: (ExtraType | WheelType | WinterWheelType)[] = [
        ...CarDataConsolidator.getAllRelevantBasketExtras(basket, items),
    ];

    combinedExtras.push(wheel);

    // Add the winterwheel
    if (basket.winterWheel) {
        combinedExtras.push(basket.winterWheel);
    }

    const data: { options: string[]; accessories: string[] } = { options: [], accessories: [] };

    // Only if financeOption is monthly should the financePackExtras be added, currently only relevant for getExternalConfigurationObject
    // TODO Might need a refactor to also utilize getSelectedFinancePackEquipment here instead of relying on this only
    if (financeOption === "monthly") {
        combinedExtras.push(...basket.financePackExtras);
    }

    const standardFinancePacksExtras = basket.financePacks
        .reduce(
            (packEquipment, pack) => {
                return [...packEquipment, ...pack.equipment];
            },
            [] as (ExtraType | WheelType | ServiceProductType)[],
        )
        .filter((extra) => extra.standard);

    if (combinedExtras.length > 0) {
        data.options = getExtraIds("option", combinedExtras, useShortIds, excludeStandard).filter(
            (extraId) => !find(extraId, standardFinancePacksExtras),
        );
        data.accessories = getExtraIds("accessory", combinedExtras, useShortIds, excludeStandard).filter(
            (extraId) => !find(extraId, standardFinancePacksExtras),
        );
    }

    return data;
};
export const findPackExtraParent = (packExtraId: string, packs: PackType[]): PackType => {
    const parentPack = packs.reduce((acc, pack) => {
        if (pack && find(packExtraId, pack.equipment)) {
            return pack;
        }
        return acc;
    });

    return parentPack;
};

export const getColourSwatch = (
    settings: CommonSettingsType,
    colour: ExteriorColourTexusType | ColourTexusType | ColourFromOtherGradesTexusType,
): string => {
    const colourSwatch = colour.Assets
        ? colour.Assets.find((asset) => asset.Type.toLowerCase().replace(/\s*/g, "") === "colourchooser-fullsize")
        : "";
    return colourSwatch ? getCARDBPath(settings, colourSwatch.Url) : "";
};
